





























  import { ValidationProvider } from 'vee-validate';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
  import { getNames } from 'i18n-iso-countries';
  import { FormIcons } from '@/components/common/form-elements/FormInput.vue';

@Component({
  components: {
    FormInvalidMessage,
    ValidationProvider,
  },
  inheritAttrs: false,
})

  export default class FormSelect extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop() placeholder: string | undefined;
  @Prop() rules!: string;
  @Prop() label!: string;
  @Prop() options!: {
    value: any,
    text: string
  }[] | 'countries';
  @Prop({ default: '' }) icon!: FormIcons;
  @Prop({ default: true }) bails!: boolean;

  localOptions: any;

  created() {
    if (this.options === 'countries') {
      const lang: string = this.$i18n.locale;
      const names = getNames(lang);
      this.localOptions = Object.entries(names).map((pair) => ({ value: pair[0], text: pair[1] }));
    } else {
      this.localOptions = this.options;
    }
  }

  inputEvent(target) {
    this.$emit('input', target.value);
  }
  }
