































import { ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import DatePicker from 'vue2-datepicker';

@Component({
  components: {
    FormInvalidMessage,
    DatePicker,
    ValidationProvider,
  },
  inheritAttrs: false,
})

export default class FormDatePicker extends Vue {
  @Prop({ default: true }) bails!: boolean;
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: false }) dateToMidnight!: boolean;
  @Prop({ default: true }) editable!: boolean;
  @Prop({ default: 'DD/MM/YYYY' }) format!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) name!: string;
  @Prop() placeholder: string | undefined;
  @Prop({ default: false }) range!: boolean;
  @Prop() rules!: string;
  @Prop({ default: 'date' }) type!: string;
  @Prop({ default: null }) value!: Date | null;
  @Prop({ default: null }) disabledDate!: Function | null;
  @Prop({ default: null }) defaultValue!: Date | null;

  get datePickerDynamicProps(): Object {
    const props: { [key: string]: any } = {};
    if (this.disabledDate) {
      props.disabledDate = this.disabledDate;
    }
    if (this.defaultValue) {
      props.defaultValue = this.defaultValue;
    }
    return props;
  }

  updateDate(date: Date | null): void {
    let dateToEmit = date;
    if (date && this.dateToMidnight) {
      dateToEmit = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    this.$emit('input', dateToEmit);
  }
}
