import { IdentCheckoutRouteNames } from '@/helpers/checkout';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import { Asset } from '@/store/models/asset';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { State } from '@/store/models';
import Navbar from '@/components/common/navbar/Navbar.vue';
import Footer from '@/components/common/footer/Footer.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import CheckoutHeader from '@/components/checkout/header/CheckoutHeader.vue';
import FormStatus from './form/IdentificationForm.vue';

@Component({
  components: {
    CheckoutHeader,
    FormStatus,
    Navbar,
    Footer,
    FormInput,
  },
})
export default class Identification extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.identification.title').toString()),
    };
  }

  @Prop({ default: true })
  header!: boolean; // indicates that we're not in the checkout flow

  @StateClass user!: State['user'];
  @StateClass identificationRequest!: State['identificationRequest'];
  @StateClass operations!: State['operations'];

  @Getter isInvestor!: boolean;

  IdentRouteNames = IdentRouteNames;

  get isMainRoute(): boolean {
    return this.$route.matched.length === 1 || this.$route.name === IdentCheckoutRouteNames.MAIN;
  }

  get showFormStatus(): boolean {
    return (!this.isInvestor && !!this.identificationRequest) || (this.identificationStatus === IdentificationRequestStatus.Approved &&
      (this.$route.name !== IdentRouteNames.IDIN && this.$route.name !== IdentCheckoutRouteNames.IDIN)); // on IDIN we show the IDIN success screen
  }

  get sendingForm(): boolean {
    return this.operations.name === 'SEND_PRIVATE_IDENTIFICATION_PROCESSING' || this.operations.name === 'SEND_BUSINESS_IDENTIFICATION_PROCESSING';
  }

  get identificationStatus(): string {
    if (this.user && this.isInvestor) {
      return IdentificationRequestStatus.Approved;
    }

    const customIdent = this.identificationRequest;
    if (customIdent) {
      if (customIdent.status === IdentificationRequestStatus.Initial) {
        return 'pending';
      }
      if (customIdent.status === IdentificationRequestStatus.Rejected) {
        return IdentificationRequestStatus.Rejected;
      }
    }

    return IdentificationRequestStatus.Initial;
  }

  get redirect(): any {
    return this.$route.query.redirect;
  }

  get from(): any {
    return this.$route.query.from;
  }

  goBack(): void {
    this.$router.push(this.from || '/account/instellingen/identificatie')
        .catch((): void => {});
  }

  goTo(identName: IdentRouteNames): void {
    const query: { [key: string]: any } = {};
    if (this.redirect) {
      query.redirect = this.redirect;
    }
    if (this.from) {
      query.from = this.from;
    }
    this.$router.push({
      name: identName,
      query,
    }).catch((): void => {});
  }
}
