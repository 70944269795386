import { steps } from '@/helpers/checkout';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class/lib/bindings';
import { State } from '@/store/models';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import { Getter } from 'vuex-class';

@Component({})

export default class CheckoutHeader extends Vue {
  steps = steps;
  IdentificationRequestStatus = IdentificationRequestStatus;

  @Prop({ default: -1 }) currentRouteIndex!: number;

  @StateClass user!: State['user'];
  @StateClass identificationRequest!: State['identificationRequest'];
  @Getter isInvestor!: boolean;

  get identificationStatus(): string {
    if (this.user && this.isInvestor) {
      return IdentificationRequestStatus.Approved;
    }

    const customIdent = this.identificationRequest;
    if (customIdent) {
      if (customIdent.status === IdentificationRequestStatus.Initial) {
        return 'pending';
      }
      if (customIdent.status === IdentificationRequestStatus.Rejected) {
        return IdentificationRequestStatus.Rejected;
      }
    }
    return IdentificationRequestStatus.Initial;
  }
}
