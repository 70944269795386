import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import { UserGender, PrivateIdentification } from '@/store/models/identificationRequest';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import FormSelect from '@/components/common/form-elements/FormSelect.vue';
import FormFileInput from '@/components/common/form-elements/FormFileInput.vue';
import { LocalizedCountryNames, getNames } from 'i18n-iso-countries';

const allowedImgTypes = ['image/gif', 'image/jpeg', 'image/png'];

@Component({
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider,
    FormDatePicker,
    FormInput,
    FormSelect,
    FormInvalidMessage,
    FormFileInput,
  },
})
export default class IdentificationWorld extends Vue {
  name: string = '';
  surname: string = '';
  telephone: string = '';
  dateOfBirth: Date | null = null;
  bankAccount: string = '';
  gender: UserGender | null = null;
  nationality: string = '';
  streetAddress: string = '';
  houseNumber: string = '';
  city: string = '';
  country: string = '';
  postalCode: string = '';
  passport: File | null = null;

  @Action sendPrivateIdentification!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  @StateClass operations!: State['operations'];

  @Ref() readonly passportUpload!: HTMLInputElement;
  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Watch('operations')
  onOperationsChange(newOperation: any, oldOperation: any): void {
    if (newOperation.name === 'SEND_PRIVATE_IDENTIFICATION_ERROR'
      && oldOperation.name === 'SEND_PRIVATE_IDENTIFICATION_PROCESSING') {
      this.addToastMessage({
        text: newOperation.error,
        type: 'danger',
      });
    }
  }

  /**
   * Returns list of countries.
   */
  get countries(): LocalizedCountryNames {
    const lang: string = this.$i18n.locale;
    return getNames(lang);
  }

  get genderOptions(): { text: string, value: UserGender }[] {
    return [
      { text: this.$t('common.male') as string, value: UserGender.Male },
      { text: this.$t('common.female') as string, value: UserGender.Female },
    ];
  }

  get privateProcessing(): boolean {
    return this.operations.name === 'SEND_PRIVATE_IDENTIFICATION_PROCESSING';
  }

  get redirect(): any {
    return this.$route.query.redirect;
  }

  get from(): any {
    return this.$route.query.from;
  }

  async submitPrivateCheckout(): Promise<void> {
    if (await this.form.validate()) {
      const identPrivateRequest: Omit<PrivateIdentification, 'status'> = {
        name: this.name,
        surname: this.surname,
        telephone: this.telephone,
        streetAddress: this.streetAddress,
        houseNumber: this.houseNumber,
        bankAccount: this.bankAccount,
        gender: this.gender!,
        // sending as number since its a Cloud Function the handler
        // @ts-ignore
        dateOfBirth: Date.UTC(this.dateOfBirth!.getFullYear(), this.dateOfBirth!.getMonth(), this.dateOfBirth!.getDate()),
        city: this.city,
        country: this.country,
        nationality: this.nationality,
        postalCode: this.postalCode,
        // @ts-ignore (it's transformed in the sendPrivateIdentification action)
        passport: this.passport,
      };

      this.sendPrivateIdentification(identPrivateRequest);
      return;
    }
    this.addToastMessage({
      text: 'There are missing fields or they are incorrectly submitted.',
      type: 'danger',
    });
  }

  onFileChange(event: any): void {
    const file = event.target.files[0] || event.dataTransfer.files[0];
    if (!file) {
      return;
    }

    // Resetting input if file type is not compatible
    if (!allowedImgTypes.some((type): boolean => type === file.type)) {
      this.passportUpload.value = '';
    }

    this.passport = file;
  }

  goTo(): void {
    const query: { [key: string]: any } = {};
    if (this.redirect) {
      query.redirect = this.redirect;
    }
    if (this.from) {
      query.from = this.from;
    }
    this.$router.push({
      name: IdentRouteNames.MAIN,
      query,
    }).catch((): void => {
    });
  }
}
